<template>
    <!-- <LoaderComponent v-if="parent_loading"></LoaderComponent> -->

    <section class="container mx-auto py-5 sm:px-10 px-3 space-y-5">
        <ShipmentHeader></ShipmentHeader>

        <DangerAlert :message="errors.message"></DangerAlert>


        <div class="grid lg:grid-cols-8 gap-5">

            <div class="lg:col-span-6 py-10 px-5 bg-[#F5F3F6] rounded">

                <h3 class="mb-4 text-2xl font-semibold leading-none text-gray-900"> Create your Shipment</h3>
                <p class="block mb-2 text-sm text-gray-900">Confirm your package effortlessly and take control of your
                    shipments. Quick and easy, our confirmation process ensures your packages are on the way to their
                    destination with just a few clicks.</p>
                <div class="grid grid-cols-1 md:grid-cols-3 justify-center items-start gap-5 py-5">


                    <!-- SENDER ADDRESS -->
                    <div class="bg-white shadow-md rounded-xl p-5 h-full">

                        <template v-if="pkg.ship_from">
                            <b>Sender</b>
                            <button type="button"
                                class=" float-right font-medium text-blue-600 dark:text-blue-500 hover:underline px-2"
                                @click="editAddressModal(pkg?.ship_from?.id, 'ship_from')">Edit</button>
                            <p class="pt-2"><b>Name:</b> {{ pkg?.ship_from?.fullname }}</p>
                            <p><b>Address:</b> {{ pkg?.ship_from?.address }}</p>
                            <p v-if="pkg?.ship_from?.address_2"><b>Address 2:</b> {{ pkg?.ship_from?.address_2 }}</p>
                            <p v-if="pkg?.ship_from?.address_3"><b>Address 3:</b> {{ pkg?.ship_from?.address_3 }}</p>
                            <p><b>Zip code:</b> {{ pkg?.ship_from?.zip_code }}</p>
                            <p><b>City:</b> {{ pkg?.ship_from?.city }}</p>
                            <p><b>Country:</b> {{ pkg?.ship_from?.country?.name }}</p> <br>

                            <p><b>Residential:</b> {{ pkg?.ship_from?.is_residential ? "Yes" : "No" }}</p> <br>
                        </template>

                        <template v-else>
                            <div class="flex justify-center h-full w-full items-center">
                                <div class="flex flex-col items-center justify-center gap-2">
                                    <button @click="openAddressModal('ship_from')"
                                        class="hover:bg-black hover:text-white hover:border-white hover:border-2 h-[30px] md:h-[40px] w-[30px] md:w-[40px] text-black border-2 border-black font-semibold flex justify-center items-center rounded-full text-xl"
                                        type="button"><span>+</span></button>
                                    <p>Add Sender</p>
                                </div>
                            </div>
                        </template>

                    </div>

                    <!-- RECEIVER ADDRESS -->
                    <div class="bg-white shadow-md rounded-xl p-5 h-full" v-if="pkg.ship_from">
                        <template v-if="pkg.ship_to">
                            <b>Receiver</b>
                            <button type="button"
                                class=" float-right font-medium text-blue-600 dark:text-blue-500 hover:underline px-2"
                                @click="editAddressModal(pkg?.ship_to?.id, 'ship_to')">Edit</button>

                            <p class="pt-2"><b>Name:</b> {{ pkg?.ship_to?.fullname }}</p>
                            <p><b>Address:</b> {{ pkg?.ship_to?.address }}</p>
                            <p v-if="pkg?.ship_to?.address_2"><b>Address 2:</b> {{ pkg?.ship_to?.address_2 }}</p>
                            <p v-if="pkg?.ship_to?.address_3"><b>Address 3:</b> {{ pkg?.ship_to?.address_3 }}</p>
                            <p><b>Zip code:</b> {{ pkg?.ship_to?.zip_code }}</p>
                            <p><b>City:</b> {{ pkg?.ship_to?.city }}</p>
                            <p><b>Country:</b> {{ pkg?.ship_to?.country?.name }}</p> <br>

                            <p><b>Residential:</b> {{ pkg?.ship_to?.is_residential ? "Yes" : "No" }}</p> <br>
                        </template>

                        <template v-else>
                            <div class="flex justify-center h-full w-full items-center">
                                <div class="flex flex-col items-center justify-center gap-2">
                                    <button @click="openAddressModal('ship_to')"
                                        class="hover:bg-black hover:text-white hover:border-white hover:border-2 h-[30px] md:h-[40px] w-[30px] md:w-[40px] text-black border-2 border-black font-semibold flex justify-center items-center rounded-full text-xl"
                                        type="button"><span>+</span></button>
                                    <p>Add Receiver</p>
                                </div>
                            </div>
                        </template>
                    </div>

                    <!-- PACKAGE SHIPMENT -->
                    <div class="bg-white shadow-md rounded-xl p-3 h-full" v-if="pkg.ship_from && pkg.ship_to">
                        <template v-if="pkg.service_label">
                            <b>Shipment</b>
                            <button type="button"
                                class=" float-right font-medium text-blue-600 dark:text-blue-500 hover:underline px-2"
                                @click="editPackage()">Edit</button>

                            <p class="pt-2"><b>Package ID:</b> {{ pkg.id }}</p>

                            <p><b>Service:</b> {{ pkg.service_label }}</p>
                            <p><b>Box:</b> {{ pkg.package_type_code }}</p>

                            <p class="pt-2"><b>Total Packages:</b> {{ pkg.boxes.length }}</p>
                            <b>Total Weight:</b> {{ pkg.boxes.reduce((total, box) => total + box.weight, 0) }} lb

                            <!-- <template v-for="box in pkg.boxes" :key="box.id">
                                <div class="border p-1 text-sm">
                                    <b>Dimension:</b> {{ box.length }} x {{ box.width }} x {{ box.height }} {{box.dim_unit}} <br> <b>Weight:</b> {{ box.weight }} {{ box.weight_unit }}
                                </div>
                            </template> -->
                        </template>

                        <template v-else>
                            <div class="flex justify-center h-full w-full items-center">
                                <div class="flex flex-col items-center justify-center gap-2">
                                    <button @click="openPackageModal()"
                                        class="hover:bg-black hover:text-white hover:border-white hover:border-2 h-[30px] md:h-[40px] w-[30px] md:w-[40px] text-black border-2 border-black font-semibold flex justify-center items-center rounded-full text-xl"
                                        type="button"><span>+</span></button>
                                    <p>Add Package</p>
                                </div>
                            </div>
                        </template>
                    </div>

                    <CustomsComponent v-if="pkg.pkg_ship_type == 'international'"></CustomsComponent>
                    <SignatureComponent v-if="pkg.ship_to && pkg.pkg_ship_type == 'domestic'" :pkg="pkg"
                        @fetchPackage="fetchPackage" @checkPackagePaymentValidation="checkPackagePaymentValidation">
                    </SignatureComponent>
                </div>

                <template v-if="!loading && pkg.service_code">
                    <div class="space-y-3 bg-[#ffffd9] p-4">
                        <h3 class="text-xl font-semibold">Terms & Conditions</h3>

                        <ul class="list-decimal list-inside space-y-2">
                            <li>
                                I hereby acknowledge and accept the terms and conditions of Self Ship Label for my
                                purchase.
                            </li>
                            <li>
                                I confirm there are NO restricted, prohibited, hazardous materials, or dangerous goods
                                in
                                this shipment.
                            </li>
                            <li>
                                I understand that if an item in my shipment is undeclared, restricted, prohibited,
                                hazardous
                                or a dangerous good, it will be discarded without reimbursement.
                            </li>
                            <li>
                                I understand I may be billed more if the package exceeds the weight and dimensions I
                                provided.
                            </li>
                            <li>
                                I understand that the recipient may be charged for duties and taxes upon delivery. Final
                                costs are determined by customs authorities at the time of import into the destination
                                country.
                            </li>
                        </ul>

                        <p class="flex items-center gap-5">
                            <input type="checkbox" class="h-[20px] w-[20px]" @click="this.terms_agree = true">
                            I acknowledge and agree to all the terms & conditions.
                        </p>
                    </div>
                </template>

            </div>

            <div class="lg:col-span-2 bg-[#F5F3F6]">
                <div>

                    <div class="w-full py-3 text-center bg-[#f7a900] text-white font-bold"><i
                            class="fa-solid fa-circle-exclamation"></i> <span>Shipment Information</span></div>

                    <template v-if="loading">
                        <!-- ============= SideBar Loader =========== -->
                        <div class="flex justify-center items-center h-[120px] w-full">
                            <div class="loader !w-[60px] !h-[60px] border-[7px]"></div>
                        </div>
                    </template>

                    <template v-else>
                        <!-- Section Content without loader -->
                        <div class="p-4">
                            <h4 class="font-medium text-xl">{{ pkg.service_label }}</h4>
                            <!-- <p>2 to 5 business days delivery</p> -->
                            <ul class="space-y-3 mt-3">
                                <li>
                                    <span v-if="pkg.ship_from"><i
                                            class="fa-solid fa-circle-check text-xl text-green-700"></i> Sender
                                        Address</span>
                                    <span v-else class="text-[#f70000]"><i
                                            class="fa fa-info-circle text-xl mx-1"></i>Sender
                                        address is required.</span>
                                </li>

                                <li>
                                    <span v-if="pkg.ship_to"><i
                                            class="fa-solid fa-circle-check text-xl text-green-700"></i>
                                        Receiver Address</span>
                                    <span v-else class="text-[#f70000]"><i
                                            class="fa fa-info-circle text-xl mx-1"></i>Receiver address is
                                        required.</span>
                                </li>

                                <li>
                                    <span v-if="pkg.custom_form_status == 1"><i
                                            class="fa-solid fa-circle-check text-xl text-green-700"></i> Customs
                                        Declaration</span>
                                    <span v-else-if="pkg.pkg_ship_type == 'international'" class="text-[#f70000]"><i
                                            class="fa fa-info-circle text-xl mx-1"></i>Customs Declaration is
                                        required.</span>
                                </li>

                                <li>
                                    <span v-if="pkg.service_code"><i
                                            class="fa-solid fa-circle-check text-xl text-green-700"></i> Weight &
                                        Dimension</span>
                                    <span v-else class="text-[#f70000]"><i
                                            class="fa fa-info-circle text-xl mx-1"></i>Weight
                                        & Dimension is required.</span>
                                </li>

                                <!-- <li>
                                    <a class="text-[#f70000] underline" href="/shipping-restrictions" target="_blank">
                                        Restricted and prohibited items
                                    </a>
                                </li> -->
                            </ul>
                        </div>
                    </template>


                    <div class="w-full py-3 text-center bg-[#f7a900] text-white font-bold"><span>Price breakdown:</span>
                    </div>

                    <ul class="space-y-3 p-4">
                        <li class="flex justify-between items-center"><span>Shipping charges:</span>
                            <span>${{ pkg.grand_total ?? 0 }}</span>
                        </li>
                        <li class="flex justify-between items-center text-xl font-medium"><span>Total:</span>
                            <span>${{ pkg.grand_total ?? 0 }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <DangerAlert :message="errors.message"></DangerAlert>

        <div class="flex sm:px-10 py-5">
            <BackButton></BackButton>

            <template v-if="pkg.ship_from">
                <div class="flex space-x-2 ml-auto">
                    <button type="button" @click="saveAsDraft"
                        class="text-white bg-[#9e9787] hover:bg-[#807f7d] focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                        Save as Draft
                    </button>

                    <button type="button" @click="payLater"
                        :class="pkg.service_code ? 'bg-[#f6ba3b] hover:bg-[#f7a900]' : 'bg-[#f6ba3b] opacity-50 cursor-not-allowed'"
                        :disabled="!(pkg.service_code)"
                        class="text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                        Pay Later
                    </button>

                    <button type="button" @click="payment"
                        :class="pkg.service_code ? 'bg-[#f6ba3b] hover:bg-[#f7a900]' : 'bg-[#f6ba3b] opacity-50 cursor-not-allowed'"
                        :disabled="!(pkg.service_code)"
                        class="text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                        Confirm & Pay
                    </button>
                </div>
            </template>


        </div>

    </section>

    <template v-if="address_form.address_modal">
        <div id="defaultModal" tabindex="-1" aria-hidden="true"
            class="z-[1000] fixed bg-[rgba(11,11,11,0.5)] m-[0!important] top-0 left-0 right-0 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 max-h-[100vh] h-[100vh] ">
            <div class="relative m-auto w-full max-w-2xl h-full max-h-[100vh]">
                <!-- Modal content -->
                <div
                    class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white w-full rounded-lg shadow">
                    <!-- Modal header -->
                    <div class="flex items-start justify-between px-4 pt-4 pb-2 border-b rounded-t">
                        <h3 class="mb-2 text-2xl leading-none font-bold text-gray-900">
                            <span v-if="address_form.type == 'ship_from'">Sender Address</span>
                            <span v-if="address_form.type == 'ship_to'">Receiver Address</span>
                        </h3>
                        <button type="button" @click="closeAddressModal"
                            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            data-modal-hide="defaultModal">
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span class="sr-only">Close modal</span>
                        </button>
                    </div>

                    <!-- Body -->
                    <div class="py-3 px-5 overflow-y-auto">
                        <DangerAlert :message="errors.message"></DangerAlert>
                        <div class="mb-4">
                            <label for="address1" class="block mb-2 text-sm font-medium text-gray-900">Addresses
                                List</label>
                            <select v-model="address_form.selected_address_id" @change="changeAddress()"
                                :disabled="loading"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 blue-500">
                                <option value="">{{ loading ? 'Loading ...' : 'Select Address' }}</option>
                                <template v-for="address in addresses" :key="address.id">
                                    <option :value="address.id">
                                        {{ address.fullname }}, {{ address.address }}, {{ address.city }}, {{
                                            address.state
                                        }}<span v-if="address.state">,</span> {{ address.country_code }}
                                    </option>
                                </template>
                            </select>
                        </div>

                        <form @submit.prevent="createUpdateAddress" class="space-y-3">
                            <div
                                class="grid gap-4 mb-4 sm:grid-cols-2 h-[450px] md:h-[520px] overflow-y-auto pr-2 pl-2 max-h-[20rem] 2xl:max-h-[20rem] 3xl:max-h-[20rem]">

                                <div>
                                    <label for="address1" class="block mb-2 text-sm font-medium text-gray-900">Company
                                        Name</label>
                                    <input type="text" v-model="address_form.company_name"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 blue-500"
                                        placeholder="">
                                    <span class="text-red-600">{{ errors?.errors?.company_name }}</span>
                                </div>
                                <div>
                                    <label for="address1" class="block mb-2 text-sm font-medium text-gray-900">Full
                                        Name *</label>
                                    <input type="text" v-model="address_form.fullname"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 blue-500"
                                        placeholder="">
                                    <span class="text-red-600">{{ errors?.errors?.fullname }}</span>
                                </div>
                                <div>
                                    <label for="address1" class="block mb-2 text-sm font-medium text-gray-900">Is
                                        Residential?</label>
                                    <select v-model="address_form.is_residential"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 blue-500">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <span class="text-red-600">{{ errors?.errors?.is_residential }}</span>
                                </div>
                                <div>
                                    <label for="address1" class="block mb-2 text-sm font-medium text-gray-900">Address
                                        line 1 *</label>
                                    <input type="text" v-model="address_form.address" id="address1"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 blue-500"
                                        placeholder="">
                                    <span class="text-red-600">{{ errors?.errors?.address }}</span>
                                </div>
                                <div>
                                    <label for="address2" class="block mb-2 text-sm font-medium text-gray-900">Address
                                        line 2</label>
                                    <input type="text" v-model="address_form.address_2" id="address2"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                        placeholder="">
                                    <span class="text-red-600">{{ errors?.errors?.address_2 }}</span>
                                </div>
                                <div>
                                    <label for="address2" class="block mb-2 text-sm font-medium text-gray-900">Address
                                        line 3</label>
                                    <input type="text" v-model="address_form.address_3" id="address2"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                        placeholder="">
                                    <span class="text-red-600">{{ errors?.errors?.address_3 }}</span>
                                </div>
                                <div>
                                    <label for="country" class="block mb-2 text-sm font-medium text-gray-900">Country
                                        *</label>
                                    <select v-model="address_form.country_id"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5">
                                        <template v-if="address_form.type == 'ship_to'">
                                            <option value="">Select</option>
                                        </template>
                                        <template v-for="country in countries" :key="country.id">
                                            <option :value="country.id">{{ country.name }}</option>
                                        </template>
                                    </select>
                                    <span class="text-red-600">{{ errors?.errors?.country_id }}</span>
                                </div>
                                <div>
                                    <label for="country" class="block mb-2 text-sm font-medium text-gray-900">
                                        <span>State</span>
                                        <span v-if="address_form.country_id == 226">*</span>
                                    </label>

                                    <template v-if="address_form.country_id == 226">
                                        <select v-model="address_form.state"
                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5">
                                            <template v-for="state in states" :key="state.id">
                                                <option :value="state.abbr">{{ state.name }}</option>
                                            </template>
                                        </select>
                                    </template>

                                    <template v-else>
                                        <input type="text" v-model="address_form.state"
                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                            placeholder="">
                                    </template>

                                    <span class="text-red-600">{{ errors?.errors?.state }}</span>
                                </div>
                                <div>
                                    <label for="city" class="block mb-2 text-sm font-medium text-gray-900">City
                                        *</label>
                                    <input type="text" v-model="address_form.city"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                        placeholder="">
                                    <span class="text-red-600">{{ errors?.errors?.city }}</span>
                                </div>
                                <div>
                                    <label for="postal-code" class="block mb-2 text-sm font-medium text-gray-900">
                                        Zip
                                        code *</label>
                                    <input type="text" v-model="address_form.zip_code"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                        placeholder="">
                                    <span class="text-red-600">{{ errors?.errors?.zip_code }}</span>
                                </div>
                                <div>
                                    <label for="postal-code" class="block mb-2 text-sm font-medium text-gray-900">Tax
                                        ID</label>
                                    <input type="text" v-model="address_form.tax_no"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                        placeholder="">
                                    <span class="text-red-600">{{ errors?.errors?.tax_no }}</span>
                                </div>
                                <div>
                                    <label for="postal-code" class="block mb-2 text-sm font-medium text-gray-900">Phone
                                        *</label>
                                    <input type="text" v-model="address_form.phone"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                        placeholder="">
                                    <span class="text-red-600">{{ errors?.errors?.phone }}</span>
                                </div>
                                <div>
                                    <label for="postal-code" class="block mb-2 text-sm font-medium text-gray-900">Email
                                        *</label>
                                    <input type="email" v-model="address_form.email"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                        placeholder="">
                                    <span class="text-red-600">{{ errors?.errors?.email }}</span>
                                </div>
                            </div>

                            <button type="submit"
                                class="text-white bg-[#EDAC24] hover:bg-[#f7a900] focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                {{ loading ? "Loading ..." : "Submit" }}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </template>

    <template v-if="package_form.modal">
        <div id="defaultModal" tabindex="-1" aria-hidden="true"
            class="z-[1000] fixed bg-[rgba(11,11,11,0.5)] m-[0!important] top-0 left-0 right-0 w-full p-4 overflow-x-hidden overflow-y-auto inset-0 max-h-[100vh] ">
            <div class="relative m-auto w-full max-w-5xl h-full max-h-[100vh]">
                <!-- Modal content -->
                <div
                    class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white w-full rounded-lg shadow">
                    <!-- Modal header -->
                    <div class="flex items-start justify-between py-2 px-4 pt-4 border-b rounded-t">
                        <h3 class="mb-2 text-2xl leading-none font-bold text-gray-900">
                            Shipment
                        </h3>
                        <button type="button" @click="closePackageModal"
                            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            data-modal-hide="defaultModal">
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span class="sr-only">Close modal</span>
                        </button>
                    </div>

                    <!-- Body -->
                    <div class="py-3 px-2 sm:px-5 max-h-[450px] md:max-h-[520px] overflow-y-auto overflow-x-hidden">
                        <DangerAlert :message="errors.message"></DangerAlert>

                        <form @submit.prevent="submitPackage" class="space-y-3">
                            <div class="flex flex-col gap-4 h-fit pt-2 py-4">
                                <div class="flex flex-col gap-2">
                                    <template v-for="(dimension, index) in fetch_rates_form.dimensions"
                                        :key="dimension.id">
                                        <div class="grid grid-cols-11 pb-2 gap-1 sm:gap-1 rounded-lg px-2 pt-2">
                                            <div class="col-span-2">
                                                <template v-if="index == 0">
                                                    <label for="input-group-3"
                                                        class="block mb-2 text-sm font-medium text-gray-900">
                                                        <span class="lg:hidden">Pkgs</span>
                                                        <span class="hidden lg:inline">No. of Packages</span>
                                                    </label>
                                                </template>
                                                <div class="relative w-full">
                                                    <div
                                                        class="absolute inset-y-0 left-0 hidden sm:flex items-center pl-3.5 pointer-events-none">
                                                        <i class="fa-solid fa-list"></i>
                                                    </div>
                                                    <input id="input-group-3" v-model="dimension.no_of_pkg"
                                                        placeholder=""
                                                        class="sm:bg-gray-50 border-b sm:outline-4 outline-none sm:border border-gray-300 text-gray-900 text-sm sm:rounded-lg focus:ring-[#EDAC24] focus:border-[#EDAC24] block w-full sm:pl-9 md:pl-10 p-2 sm:pl-9 md:pl-10 p-2 sm:placeholder-opacity-100 placeholder-hide-on-mobile" />
                                                </div>
                                                <span class="text-red-600 text-base">{{
                                                    errors[`dimensions.0.no_of_pkg`] }}</span>
                                            </div>

                                            <div class="col-span-2">
                                                <template v-if="index == 0">
                                                    <label for="input-group-3"
                                                        class="block mb-2 text-sm font-medium text-gray-900">
                                                        Weight <small class="hidden xl:inline">(Pounds)</small></label>
                                                </template>
                                                <div class="relative w-full">
                                                    <div
                                                        class="absolute inset-y-0 left-0 hidden sm:flex items-center pl-3.5 pointer-events-none">
                                                        <i class="fa-solid fa-weight-scale"></i>
                                                    </div>
                                                    <input id="input-group-3" v-model="dimension.weight"
                                                        placeholder="Weight"
                                                        class="sm:bg-gray-50 border-b sm:outline-4 outline-none sm:border border-gray-300 text-gray-900 text-sm sm:rounded-lg focus:ring-[#EDAC24] focus:border-[#EDAC24] block w-full sm:pl-9 md:pl-10 p-2 sm:pl-9 md:pl-10 p-2 sm:placeholder-opacity-100 placeholder-hide-on-mobile" />
                                                </div>
                                                <span class="text-red-600 text-base">{{ errors[`dimensions.0.weight`]
                                                    }}</span>
                                            </div>

                                            <div class="col-span-2">
                                                <template v-if="index == 0">
                                                    <label for="input-group-3"
                                                        class="block mb-2 text-sm font-medium text-gray-900">

                                                        <span class="flex items-baseline gap-1 sm:hidden">Dimensions
                                                            <span class="text-gray-400 text-xs">(LxWxH)</span></span>

                                                        <span class="hidden sm:block">
                                                            Length <small class="hidden xl:inline">(Inches - IN)</small>
                                                        </span>
                                                    </label>
                                                </template>
                                                <div class="relative w-full">
                                                    <div
                                                        class="absolute inset-y-0 left-0 hidden sm:flex items-center pl-3.5 pointer-events-none">
                                                        <i class="fa-solid fa-ruler"></i>
                                                    </div>
                                                    <input id="input-group-3" v-model="dimension.length"
                                                        placeholder="length"
                                                        class="sm:bg-gray-50 border-b sm:outline-4 outline-none sm:border border-gray-300 text-gray-900 text-sm sm:rounded-lg focus:ring-[#EDAC24] focus:border-[#EDAC24] block w-full sm:pl-9 md:pl-10 p-2 sm:pl-9 md:pl-10 p-2 sm:placeholder-opacity-100 placeholder-hide-on-mobile" />
                                                </div>
                                                <span class="text-red-600 text-base">{{ errors[`dimensions.0.length`]
                                                    }}</span>
                                            </div>

                                            <div class="col-span-2">
                                                <template v-if="index == 0">
                                                    <label for="input-group-3"
                                                        class="opacity-0 sm:opacity-100 block mb-2 text-sm font-medium text-gray-900">
                                                        Width <small class="hidden xl:inline">(Inches - IN)</small>
                                                    </label>
                                                </template>
                                                <div class="relative w-full">
                                                    <div
                                                        class="absolute inset-y-0 left-0 hidden sm:flex items-center pl-3.5 pointer-events-none">
                                                        <i class="fa-solid fa-ruler-horizontal"></i>
                                                    </div>
                                                    <input id="input-group-3" v-model="dimension.width"
                                                        placeholder="width"
                                                        class="sm:bg-gray-50 border-b sm:outline-4 outline-none sm:border border-gray-300 text-gray-900 text-sm sm:rounded-lg focus:ring-[#EDAC24] focus:border-[#EDAC24] block w-full sm:pl-9 md:pl-10 p-2 sm:pl-9 md:pl-10 p-2 sm:placeholder-opacity-100 placeholder-hide-on-mobile" />
                                                </div>
                                                <span class="text-red-600 text-base">{{ errors[`dimensions.0.width`]
                                                    }}</span>
                                            </div>

                                            <div class="col-span-2">
                                                <template v-if="index == 0">
                                                    <label for="input-group-3"
                                                        class="opacity-0 sm:opacity-100 block mb-2 text-sm font-medium text-gray-900">
                                                        Height <small class="hidden xl:inline">(Inches - IN)</small>
                                                    </label>
                                                </template>
                                                <div class="relative w-full">
                                                    <div
                                                        class="absolute inset-y-0 left-0 hidden sm:flex items-center pl-3.5 pointer-events-none">
                                                        <i class="fa-solid fa-ruler-vertical"></i>
                                                    </div>
                                                    <input id="input-group-3" v-model="dimension.height"
                                                        placeholder="height"
                                                        class="sm:bg-gray-50 border-b sm:outline-4 outline-none sm:border border-gray-300 text-gray-900 text-sm sm:rounded-lg focus:ring-[#EDAC24] focus:border-[#EDAC24] block w-full sm:pl-9 md:pl-10 p-2 sm:pl-9 md:pl-10 p-2 sm:placeholder-opacity-100 placeholder-hide-on-mobile" />
                                                </div>
                                                <span class="text-red-600 text-base">{{ errors[`dimensions.0.height`]
                                                    }}</span>
                                            </div>

                                            <div class="col-span-1" v-if="index > 0">
                                                <button type="button" class="mt-2 text-red-600 text-lg"
                                                    @click="removeDimension(index)">
                                                    <i class="fas fa-times"></i>
                                                </button>
                                            </div>
                                            <!-- <div v-if="fetch_rates_form.dimensions.length !== (index + 1)"
                                                class="row-start-1 row-end-3 col-start-9 sm:col-start-auto sm:row-end-auto sm:row-start-auto col-span-1 flex flex-col">
                                                <label v-if="index == 0"
                                                    class="block opacity-0 mb-2 text-sm font-medium text-gray-900">
                                                    Height
                                                </label>
                                                <button type="button"
                                                    class="sm:h-auto h-[37px] lg:px-6 py-2 rounded-sm sm:rounded-md bg-white border border-red-600 text-red-600"
                                                    @click="removeDimension(index)">
                                                    <i class="fas fa-times"></i>
                                                </button>
                                            </div>

                                            <div v-if="fetch_rates_form.dimensions.length === (index + 1)"
                                                class="row-start-1 row-end-3 col-start-9 sm:col-start-auto sm:row-end-auto sm:row-start-auto col-span-1 flex flex-col">
                                                <label v-if="index == 0"
                                                    class="block opacity-0 mb-2 text-sm font-medium text-gray-900">
                                                    Height
                                                </label>
                                                <button
                                                    class="sm:h-auto h-[37px] lg:px-6 py-2 rounded-sm sm:rounded-md bg-[#EDAC24] text-white"
                                                    type="button" @click="addDimension"><i
                                                        class="fas fa-plus"></i></button>
                                            </div> -->
                                        </div>
                                    </template>

                                    <div class="flex justify-center">
                                        <button class="" type="button" @click="addDimension">
                                            <i class="fas fa-plus"></i> Add Package</button>
                                    </div>

                                    <div class="flex justify-center">
                                        <button class="text-white py-2 px-16 rounded-md mt-2"
                                            :class="loading ? 'bg-[#edad24ab]' : 'bg-[#EDAC24]'" @click="showResults">
                                            {{ loading ? 'Loading ...' : 'Get Rates' }}
                                        </button>
                                    </div>
                                </div>

                                <div class="sm:overflow-x-hidden">

                                    <table class="table w-full">

                                        <template v-for="rate in rates" :key="rate.id">

                                            <tr class="flex justify-between gap-1 sm:gap-2">
                                                <td class="flex gap-2">
                                                    <div class="flex flex-col gap-2">
                                                        <img class="w-[50px] h-[40px] sm:w-[100px] md:w-[80px] object-cover sm:h-[60px] rounded-md md:rounded-lg bg-purple-300"
                                                            src='../../assets/images/fedex-logo.jpg' alt=""
                                                            v-if="rate.code == 'fedex'">
                                                        <img class="w-[50px] h-[40px] sm:w-[100px] md:w-[80px] object-cover sm:h-[60px] rounded-md md:rounded-lg bg-purple-300"
                                                            src='../../assets/images/ups-logo.jpg' alt=""
                                                            v-if="rate.code == 'ups'">
                                                        <img class="w-[50px] h-[40px] sm:w-[100px] md:w-[80px] object-cover sm:h-[60px] rounded-md md:rounded-lg bg-purple-300"
                                                            src='../../assets/images/dhl-logo.jpg' alt=""
                                                            v-if="rate.code == 'dhl'">
                                                        <img class="w-[50px] h-[40px] sm:w-[100px] md:w-[80px] object-cover sm:h-[60px] rounded-md md:rounded-lg bg-purple-300"
                                                            src='../../assets/images/calc/usps-logo.svg' alt=""
                                                            v-if="rate.code == 'usps'">
                                                    </div>

                                                    <div class="w-[100px] sm:w-[200px] md:w-[300px]">
                                                        <div class="flex sm:h-[60px] items-center w-full">
                                                            <h5
                                                                class="font-medium text-xs md:text-base sm:text-sm md:leading-normal leading-4">
                                                                {{ rate.name }}</h5>
                                                        </div>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div class="flex h-[40px] sm:h-[60px] md:w-[90px] items-center">
                                                        <h3 class="text-sm sm:text-base font-semibold">
                                                            ${{ rate.total }}
                                                        </h3>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div class="flex h-[40px] sm:h-[60px] items-center">
                                                        <button @click="setRate(rate)"
                                                            class="bg-[#EDAC24] md:w-[100px] h-[30px] sm:w-[80px] md:h-[40px] text-sm text-white font-semibold px-4 sm:px-2 rounded-md"
                                                            type="button"><span
                                                                class="sm:block hidden">SELECT</span><span
                                                                class="sm:hidden block"><i
                                                                    class="fa-solid fa-check"></i></span></button>
                                                    </div>
                                                </td>
                                            </tr>

                                        </template>

                                    </table>

                                </div>
                            </div>
                            <!-- <div class="flex justify-end">
                                <button type="submit"
                                    class="text-white bg-[#EDAC24] hover:bg-[#f7a900] focus:ring-4 focus:outline-nonefont-medium rounded-lg text-sm px-5 py-3 text-center">
                                    {{ loading ? "Loading ..." : "Submit" }}
                                </button>
                            </div> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </template>
</template>

<script>
import DangerAlert from '../Global/Alerts/DangerAlert.vue';
import CustomsComponent from './CustomsComponent.vue';
import SignatureComponent from './SignatureComponent.vue';
import BackButton from '../Global/BackButton.vue';
import ShipmentHeader from './ShipmentHeader.vue';
// import LoaderComponent from '../Partials/LoaderComponent.vue';

export default {
    name: "BookingConfirmation",
    components: { BackButton, DangerAlert, CustomsComponent, SignatureComponent, ShipmentHeader },
    data() {
        return {
            // parent_loading: false,
            loading: false,
            terms_agree: false,

            pkg: {},
            errors: {},
            form: {
                package_id: "",
            },
            project_url: process.env.VUE_APP_PROJECT_URL,

            package_form: {
                modal: false,
                package_id: '',
            },
            rates: {},

            fetch_rates_form: {
                user_id: "",

                ship_from_country_code: "",
                ship_from_postal_code: "",
                ship_from_state: "",

                ship_to_country_code: "",
                ship_to_city: "",
                ship_to_postal_code: "",
                is_residential: "",

                address_type: false,
                insurance_amount: "",

                dimensions: [
                    {
                        no_of_pkg: 1,
                        weight: "",
                        length: "",
                        width: "",
                        height: ""
                    }
                ],

                rate: {}
            },

            set_address: {
                type: "",
                id: "",
                selected_country_code: "",
            },

            fetch_address: {
                address_type: "",
            },

            address_form: {
                address_modal: false,
                address_type: "",
                selected_address_id: "",
                company_name: "",
                fullname: "",
                is_residential: "",
                country_id: "",
                state: "",
                city: "",
                zip_code: "",
                phone: "",
                email: "",
                address: "",
                address_2: "",
                address_3: "",
                tax_no: "",
                type: "",
                update_flag: false,
                address_id: null,
            },

            addresses: [],

            profile: {},

        };
    },
    methods: {
        fetchPackage() {
            this.loading = true;
            const package_id = this.$route.params.id;

            const url = `package/get-package/${package_id}`;

            this.$axios
                .post(url, null, {
                    headers: this.$headers,
                })
                .then((response) => {
                    if (response.data.data.package != null) {
                        this.pkg = response.data.data.package;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    console.error(error);
                    this.loading = false;
                });
        },
        payment() {
            if (this.terms_agree) {
                window.scrollTo(0, 0);

                this.errors = {};
                this.loading = true;
                this.form.package_id = this.pkg.id;
                this.$axios
                    .post("package/payment", this.form, {
                        headers: this.$headers
                    })
                    .then((response) => {
                        if (response.data.data.package_status_id == 3) {
                            this.$router.push("/cart");
                        } else {
                            window.location.href = this.project_url + '/square-payment/' + response.data.data.package_id;
                        }
                    })
                    .catch((error) => {
                        this.errors = error.response.data;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                this.errors.message = 'Kindly agree to the terms and conditions by clicking.';
            }
        },
        openAddressModal(address_type) {
            // this.address_form = {};
            // this.errors = {};
            this.address_form.address_modal = true;
            this.address_form.type = address_type;
            this.address_form.is_residential = true;

            if (this.address_form.type == 'ship_from') {
                this.address_form.country_id = 226;
            } else {
                this.address_form.country_id = "";
            }

            this.fetchRecords();
            this.fetchAddressList(address_type);
        },
        editAddressModal(address_id, address_type) {

            // this.address_form = {};
            // this.errors = {};

            this.address_form.type = address_type;
            this.address_form.address_id = address_id;
            this.address_form.update_flag = true;

            this.address_form.address_modal = true;
            this.address_form.address_type = address_type;
            this.address_form.selected_address_id = address_id;

            this.fetchRecords();
            this.fetchAddressList(address_type);

            // if (address_type == 'ship_from') {
            //     this.address_form.selected_address_id = this.pkg.ship_from;
            // } else {
            //     this.address_form.selected_address_id = this.pkg.ship_to;
            // }

            this.fetchAddress(address_id);
        },
        closeAddressModal() {
            this.address_form.address_modal = false;
            this.address_form = {};
            this.errors = {};
        },
        fetchRecords() {
            const url = "data"
            this.$axios
                .post(url, {
                    headers: this.$headers,
                })
                .then((response) => {
                    this.countries = [];
                    for (const country of response.data.data.countries) {
                        if (this.address_form.type === "ship_from") {
                            if (country.iso === 'US') {
                                this.countries.push(country);
                            }
                        } else {
                            this.countries.push(country);
                        }
                    }
                    this.states = response.data.data.states;
                    this.signature_types = response.data.data.signature_types;
                })
                .catch((error) => {
                    console.log(error);
                    window.scrollTo(0, 0);
                });
        },
        createUpdateAddress() {
            this.loading = true;
            this.errors = {};

            this.$axios
                .post("address/store", this.address_form, {
                    headers: this.$headers
                })
                .then((response) => {
                    if (response.data.status == true) {
                        this.fetchPackage();
                        this.closeAddressModal();
                        this.selectAddress(response.data.data.address_id, response.data.data.address_type);
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error)
                    this.errors = error.response.data;
                    console.log(error.response.data);
                    this.loading = false;
                    window.scrollTo(0, 0);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        selectAddress(id, type) {

            this.loading = true;
            this.errors = {};
            this.set_address.id = id;
            this.set_address.type = type;
            this.set_address.selected_country_code = this.selected_ship_to_country;
            this.set_address.package_id = this.$route.params.id;


            const url = "package/set-address"
            this.$axios
                .post(url, this.set_address, {
                    headers: this.$headers,
                })
                .then((response) => {
                    console.log(response);
                    // this.addresses = response.data.data.addresses;
                    this.$router.push(`/shipment/${response.data.data.package_id}`)
                        .then(() => {
                            this.fetchPackage(); // Fetch the package after redirect
                        });
                    this.loading = false;
                })
                .catch((error) => {
                    this.errors = error.response.data;
                    window.scrollTo(0, 0);
                    this.fetchPackage();
                    this.loading = false;
                });
        },
        openPackageModal() {
            this.package_form.modal = true;
            this.fetchPackage();

            this.fetch_rates_form.ship_from_country_code = this.pkg.ship_from.country_code;
            this.fetch_rates_form.ship_from_postal_code = this.pkg.ship_from.zip_code;
            this.fetch_rates_form.ship_from_state = this.pkg.ship_from.state;

            this.fetch_rates_form.ship_to_country_code = this.pkg.ship_to.country_code;
            this.fetch_rates_form.ship_to_city = this.pkg.ship_to.city;
            this.fetch_rates_form.ship_to_postal_code = this.pkg.ship_to.zip_code;
            this.fetch_rates_form.is_residential = this.pkg.ship_to.is_residential;

            this.fetch_rates_form.dimensions = [
                {
                    weight: "",
                    length: "",
                    width: "",
                    height: "",
                }
            ];

        },
        editPackage() {

            this.package_form = {};
            this.errors = {};
            this.package_form.modal = true;
            this.fetchPackage();

            this.fetch_rates_form.ship_from_country_code = this.pkg.ship_from.country_code;
            this.fetch_rates_form.ship_from_postal_code = this.pkg.ship_from.zip_code;
            this.fetch_rates_form.ship_from_state = this.pkg.ship_from.state;

            this.fetch_rates_form.ship_to_country_code = this.pkg.ship_to.country_code;
            this.fetch_rates_form.ship_to_city = this.pkg.ship_to.city;
            this.fetch_rates_form.ship_to_postal_code = this.pkg.ship_to.zip_code;
            this.fetch_rates_form.is_residential = this.pkg.ship_to.is_residential;

            this.fetch_rates_form.dimensions = this.pkg.boxes.map(item => ({
                no_of_pkg: 1,
                weight: item.weight,
                length: item.length,
                width: item.width,
                height: item.height,
            }));

        },
        closePackageModal() {
            this.package_form.modal = false;
            this.package_form = {};
            this.errors = {};
        },
        fetchRates() {

            this.fetch_rates_form.user_id = this.profile.id;
            this.loading = true;
            this.dimension_modal = false;
            this.errors = {};
            const url = "rates"
            this.$axios
                .post(url, this.fetch_rates_form, {
                    headers: this.$headers,
                })
                .then((response) => {
                    this.rates = response.data.data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                    this.dimension_modal = true;
                    this.loading = false;
                });
        },
        addDimension() {
            this.fetch_rates_form.dimensions.push({
                weight: "",
                length: "",
                width: "",
                height: "",
            });
        },
        removeDimension(index) {
            this.fetch_rates_form.dimensions.splice(index, 1);
            this.fetchRates();
        },
        showResults() {
            this.show_results = true;
            this.fetchRates();
        },
        setRate(rate) {
            this.fetch_rates_form.rate = rate;
            this.fetch_rates_form.package_id = this.$route.params.id;
            this.loading = true;
            this.errors = {};
            this.$axios
                .post("package/set-rate", this.fetch_rates_form, {
                    headers: this.$headers
                })
                .then((response) => {
                    console.log(response)
                    this.package_form.modal = false;
                    this.fetchPackage();
                })
                .catch((error) => {
                    this.errors = error.response;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        fetchAddressList(address_type) {
            this.loading = true;
            this.fetch_address.address_type = address_type;
            const url = "fetch-address-list"
            this.$axios
                .post(url, this.fetch_address, {
                    headers: this.$headers,
                })
                .then((response) => {
                    this.addresses = response.data.data.addresses;
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    window.scrollTo(0, 0);
                    this.loading = false;
                });
        },
        changeAddress() {
            this.fetchAddress(this.address_form.selected_address_id);
        },
        fetchAddress(address_id) {
            const url = "fetch-address"
            this.$axios.post(url, { address_id: address_id }, { headers: this.$headers })
                .then((response) => {
                    var address = response.data.data.address;

                    this.address_form.company_name = address.company_name;
                    this.address_form.fullname = address.fullname;
                    this.address_form.is_residential = address.is_residential == 1 ? true : false;
                    this.address_form.country_id = address.country_id;
                    this.address_form.state = address.state;
                    this.address_form.city = address.city;
                    this.address_form.zip_code = address.zip_code;
                    this.address_form.phone = address.phone;
                    this.address_form.email = address.email;
                    this.address_form.address = address.address;
                    this.address_form.address_2 = address.address_2;
                    this.address_form.address_3 = address.address_3;
                    this.address_form.tax_no = address.tax_no;
                })
                .catch((error) => {
                    console.log(error);
                    window.scrollTo(0, 0);
                });
        },
        createPackage() {
            var create_package_form = {};
            this.$axios
                .post("package/create", create_package_form, {
                    headers: this.$headers
                })
                .then((response) => {
                    console.log(response)
                })
                .catch((error) => {
                    this.errors = error.response;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        checkPackagePaymentValidation() {
            this.form.package_id = this.pkg.id;
            this.$axios
                .post("package/payment", this.form, {
                    headers: this.$headers
                })
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    this.errors = error.response.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        saveAsDraft() {
            this.errors = {};
            this.loading = true;
            this.form.package_id = this.pkg.id;
            this.$axios
                .post("package/save-as-draft", this.form, {
                    headers: this.$headers
                })
                .then((response) => {
                    console.log(response);
                    this.$router.push("/history");
                })
                .catch((error) => {
                    this.errors = error.response.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        payLater() {
            this.errors = {};
            this.loading = true;
            this.form.package_id = this.pkg.id;
            this.$axios
                .post("package/pay-later", this.form, {
                    headers: this.$headers
                })
                .then((response) => {
                    console.log(response);
                    this.$router.push("/cart");
                })
                .catch((error) => {
                    this.errors = error.response.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        fetchProfile() {
            this.loading = true;
            const url = "profile";
            this.$axios
                .post(url, null, {
                    headers: this.$headers,
                })
                .then((response) => {
                    this.profile = response.data.data.user;
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.fetchProfile();
        this.fetchPackage();
    }
};
</script>
