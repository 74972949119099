<template>
    <div class="bg-white shadow-md rounded-xl p-5 h-full">
        <template v-if="pkg.custom_form_status == 1">
            <b>Customs Form</b>
            <button type="button" class=" float-right font-medium text-blue-600 dark:text-blue-500 hover:underline px-2"
                @click="openCustomsModal()">Edit</button>
            <p class="pt-2"><b>Total:</b> ${{parseFloat(pkg.shipping_total).toFixed(2)}}</p>
        </template>

        <template v-else>
            <div class="flex justify-center h-full w-full items-center">
                <div class="flex flex-col items-center justify-center gap-2">
                    <button @click="openCustomsModal()"
                        class="hover:bg-black hover:text-white hover:border-white hover:border-2 h-[30px] md:h-[40px] w-[30px] md:w-[40px] text-black border-2 border-black font-semibold flex justify-center items-center rounded-full text-xl"
                        type="button"><span>+</span></button>
                    <p>Add Customs Form</p>
                </div>
            </div>
        </template>
    </div>

    <template v-if="customs_form.modal">
        <div id="defaultModal" tabindex="-1" aria-hidden="true"
            class="z-[1000] fixed bg-[rgba(11,11,11,0.5)] m-[0!important] top-0 left-0 right-0 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 max-h-[100vh] h-[100vh] ">
            <div class="relative m-auto w-full max-w-5xl 2xl:max-w-6xl h-full max-h-[100vh]">
                <!-- Modal content -->
                <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white w-full rounded-lg shadow">
                    <!-- Modal header -->
                    <div class="flex items-start justify-between p-4 border-b rounded-t">
                        <h3 class="mb-4 text-2xl leading-none font-bold text-gray-900">
                            Customs Declaration Form
                        </h3>
                        <button type="button" @click="closeCustomsModal"
                            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            data-modal-hide="defaultModal">
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span class="sr-only">Close modal</span>
                        </button>
                    </div>

                    <!-- Body -->
                    <div class="py-6 px-5 max-h-[450px] md:max-h-[580px] overflow-y-auto overflow-x-hidden">
                        <DangerAlert :message="errors.message"></DangerAlert>

                        <form @submit.prevent="submitCustomsForm" class="space-y-5">
                            <div class="flex flex-col gap-4 h-fit pt-2 py-4">
                                <!-- MODAL BODY CONTENT START -->
                                <div class="grid gap-4 mb-4 sm:grid-cols-2 grid-cols-1">
                                    <div class="sm:w-auto w-full sm:col-span-1 col-span-2">
                                        <label for="export" class="block mb-2 text-sm font-medium text-gray-900"> Export
                                            Reason</label>
                                        <select type="text" v-model="form.package_type"
                                            :class="{ 'border-red-500': errors.package_type }"
                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 blue-500"
                                            required>
                                            <option value="">Select</option>
                                            <option value="commercial">Commercial</option>
                                            <option value="gift">Gift</option>
                                            <option value="sample">Sample</option>
                                            <option value="personal">Personal</option>
                                        </select>
                                        <span class="text-red-600">{{ errors?.errors?.package_type }}</span>
                                    </div>
                                    <div class="sm:w-auto w-full sm:col-span-1 col-span-2">
                                        <label for="manufacture"
                                            class="block mb-2 text-sm font-medium text-gray-900">Manufacture
                                            Country</label>
                                        <select type="text" v-model="form.country"
                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 blue-500"
                                            required>
                                            <option value="">Select</option>
                                            <template v-for="country in countries" :key="country.id">
                                                <option :value="country.id">{{ country.name }}</option>
                                            </template>
                                        </select>
                                        <span class="text-red-600">{{ errors?.errors?.country }}</span>
                                    </div>

                                    <div class="w-full sm:col-span-1 col-span-2" v-if="form.shipping_total > 2500">
                                        <label for="code" class="block mb-2 text-sm font-medium text-gray-900">ITN</label>
                                        <input type="text" v-model="form.itn"
                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                            placeholder="" required="">
                                        <span class="text-red-600">{{ errors?.errors?.itn }}</span>
                                    </div>

                                    <!-- <div class="w-full col-span-2 space-y-5 py-2">
                                        <template v-for="box in pkg.boxes" :key="box.id">
                                            <p class="uppercase">
                                                <b>Dimension:</b> {{ box.length }} x {{ box.width }} x {{ box.height }}
                                                {{ box.dim_unit }} <br>
                                                <b>Weight:</b> {{ box.weight }} {{ box.weight_unit }}
                                            </p>
                                        </template>
                                    </div> -->

                                    <template v-for="(item, index) in form.items" :key="item.id">
                                        <div class="col-span-2 grid lg:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-2 lg:gap-4 border-2 border-[#ffc02c] bg-[#f8eaca] rounded-lg px-2 py-3 relative">
                                            <div class="w-full sm:col-span-1 col-span-2">
                                                <label for="description"
                                                    class="block mb-2 text-sm font-medium text-gray-900">Description</label>
                                                <input type="text" v-model="item.description"
                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                                    :class="{ 'border-red-500': errors[`items.${index}.description`] }"
                                                    placeholder="Men's blue cotton shirt" required>
                                                <span class="text-red-600">{{ errors[`items.${index}.description`]
                                                }}</span>
                                            </div>
                                            <div class="sm:col-span-2 lg:col-span-1 grid grid-cols-2 sm:grid-cols-8 items-start gap-4">
                                                <div class="col-span-1 sm:col-span-2">
                                                    <label for="code"
                                                        class="block mb-2 text-sm font-medium text-gray-900">HS Code</label>
                                                    <input type="text" v-model="item.hs_code"
                                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                                        :class="{ 'border-red-500': errors[`items.${index}.hs_code`] }"
                                                        placeholder="">
                                                    <span class="text-red-600">{{ errors[`items.${index}.hs_code`]
                                                    }}</span>
                                                </div>
                                                <div class="col-span-1 relative">
                                                    <label for="quantity"
                                                        class="block mb-2 text-sm font-medium text-gray-900">Qty</label>
                                                    <input type="text" v-model="item.quantity" @keyup="calculateTotal()"
                                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                                        :class="{ 'border-red-500': errors[`items.${index}.quantity`] }"
                                                        placeholder="" required="">
                                                    <span class="text-red-600">{{ errors[`items.${index}.quantity`]
                                                    }}</span>
                                                </div>
                                                <div class="col-span-1 sm:col-span-2 relative">

                                                    <label for="price"
                                                        class="block mb-2 text-sm font-medium text-gray-900">
                                                        Price</label>
                                                    <input type="text" v-model="item.unit_price" @keyup="calculateTotal()"
                                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                                        :class="{ 'border-red-500': errors[`items.${index}.unit_price`] }"
                                                        placeholder="" required="">
                                                    <span class="text-red-600">{{ errors[`items.${index}.unit_price`]
                                                    }}</span>
                                                </div>
                                                <div class="col-span-1 sm:col-span-3 relative">
                                                    <label for="export"
                                                        class="block mb-2 text-sm font-medium text-gray-900">Batteries</label>
                                                    <select type="text" v-model="item.batteries"
                                                        :class="{ 'border-red-500': errors[`items.${index}.batteries`] }"
                                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 blue-500"
                                                        required>
                                                        <option value="">Select</option>
                                                        <option value="0">No Battery</option>
                                                        <option value="1">Simple Batteries</option>
                                                        <option value="2">Batteries Packaed with Equipment</option>
                                                        <option value="3">Batteries Contained in Equipment</option>
                                                    </select>
                                                    <span class="text-red-600">{{ errors[`items.${index}.batteries`]
                                                    }}</span>
                                                </div>
                                                <div class="self-end pb-2.5 pr-5 text-right absolute -top-4 -right-8 text-2xl text-red-500" v-if="index != 0">
                                                    <button @click="removeItem(index)"><i class="fa-solid fa-circle-xmark"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </template>

                                    <div class="col-span-2 space-y-5">
                                        <div class="sm:space-y-0 space-y-5 sm:flex justify-between items-start">
                                            <button class="text-[#EDAC24]" @click="addItem"> + Add another item</button>
                                        </div>
                                        <hr class="bg-gray-300 h-[2px] w-full">
                                        <h4 class="font-medium text-xl text-right">Custom Total: <span class="text-base">$
                                                {{
                                                    form.shipping_total
                                                }}</span></h4>
                                    </div>
                                </div>

                                <template v-if="form.shipping_total > 2500">
                                    <div
                                        class="p-4 mb-5 text-sm text-black border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400 dark:border-yellow-800">
                                        U.S Customs requires additional documents for international
                                        shipments with values of $2,500.00 or more. Please visit the <a
                                            class="text-red-600 underline"
                                            href="https://www.trade.gov/filing-your-export-shipments-through-automated-export-system-aes"
                                            target="_blank">U.S Trade Administration</a> website for additional information.
                                        Once the
                                        documents
                                        are submitted, please provide the ITN number from the
                                        Electronic Export Information (EEI) submission below to avoid delays or returns by
                                        customs or
                                        the
                                        carrier.
                                    </div>
                                </template>
                                <!-- MODAL BODY CONTENT END -->
                            </div>
                            <div class="flex justify-end">
                                <button type="submit" @click="handleSubmit"
                                    class="text-white bg-[#EDAC24] hover:bg-[#f7a900] focus:ring-4 focus:outline-nonefont-medium rounded-lg text-sm px-5 py-3 text-center">
                                    {{ loading ? "Loading ..." : "Save & Update" }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </template>
</template>

<script>
export default {
    name: "CustomForm",
    data() {
        return {
            loading: false,
            pkg: {},
            form: {
                ship_to: "",
                ship_from: "",
                country: "",
                package_id: "",
                package_type: "",
                itn: "",
                items: [],
                shipping_total: 0,
            },
            update_rate_form: {
                package_id: "",
                rate: {}
            },
            countries: [],
            rates: [],
            errors: {},
            customs_form: {
                modal: false
            }
        };
    },
    methods: {
        addItem() {
            this.form.items.push({
                description: "",
                hs_code: "",
                quantity: 1,
                unit_price: 0,
                batteries: "",
            });
        },
        removeItem(index) {
            this.form.items.splice(index, 1);
        },
        fetchRecords() {
            const url = "data"
            this.$axios
                .post(url, {
                    headers: this.$headers,
                })
                .then((response) => {
                    this.countries = response.data.data.countries;
                })
                .catch((error) => {
                    console.log(error);
                    window.scrollTo(0, 0);
                });
        },
        submitCustomsForm() {
            this.loading = true;
            this.errors = {};

            this.form.package_id = this.pkg.id;
            
            this.$axios
                .post("package/set-custom", this.form, {
                    headers: this.$headers
                })
                .then((response) => {
                    if (response.data.success == true) {
                        this.fetchPackage();
                    }
                    this.loading = false;
                    this.customs_form.modal = false;
                })
                .catch((error) => {
                    this.errors = error.response.data;
                    this.loading = false;
                    window.scrollTo(0, 0);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        fetchPackage() {
            this.loading = true;
            const package_id = this.$route.params.id;
            const url = `package/get-package/${package_id}`;
            
            this.$axios
                .post(url, null, {
                    headers: this.$headers,
                })
                .then((response) => {
                    this.pkg = response.data.data.package;
                    this.form.items = this.pkg.package_items;
                    this.form.package_type = this.pkg.package_type;
                    this.form.country = this.pkg?.package_items[0]?.origin_country;
                    this.calculateTotal();
                    this.loading = false;
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                    this.loading = false;
                    window.scrollTo(0, 0);
                });
        },
        calculateTotal() {
            let final_amount = 0;
            this.form.items.forEach((item) => {
                final_amount += parseFloat(item.unit_price) * parseInt(item.quantity);
            });
            this.form.shipping_total = parseFloat(final_amount).toFixed(2);
        },
        updateRate(rate) {
            this.loading = true;
            this.update_rate_form.package_id = this.pkg.id;
            this.update_rate_form.rate = rate;
            this.errors = {};

            this.$axios
                .post("package/update-rate", this.update_rate_form, {
                    headers: this.$headers
                })
                .then((response) => {
                    console.log(response);
                    this.fetchPackage();
                    this.handleSubmit();
                })
                .catch((error) => {
                    this.errors = error.response;
                    window.scrollTo(0, 0);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        bookingConfirmation() {
            this.rates.forEach(rate => {
                if (rate.type == this.pkg.service_code) {
                    this.updateRate(rate);
                }
            });

            this.$router.push("booking");
        },
        openCustomsModal() {
            this.customs_form.modal = true;
        },
        closeCustomsModal() {
            this.customs_form.modal = false;
        },
    },
    mounted() {
        this.fetchPackage();
        this.addItem();
        this.fetchRecords();
    },
    components: {}
};
</script>