<template>

    <!-- <nav class="bg-gray-50 dark:bg-gray-700">
        <div class="max-w-screen-xl px-4 py-3 mx-auto">
            <div class="flex items-center">
                <ul class="flex flex-row font-medium mt-0 space-x-8 rtl:space-x-reverse text-sm">
                    <li>
                        <router-link :to="{ name: 'shipment' }" class="text-gray-900 dark:text-white"
                            :class="getActiveClass('shipment')">Create Shipment</router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'partial' }" class="text-gray-900 dark:text-white"
                            :class="getActiveClass('partial')">Partial Package</router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'draft' }" class="text-gray-900 dark:text-white"
                            :class="getActiveClass('draft')">Draft Package</router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'history' }" class="text-gray-900 dark:text-white"
                            :class="getActiveClass('history')">Order History</router-link>
                    </li>

                    <li>
                        <router-link :to="{ name: 'cart' }" class="text-gray-900 dark:text-white"
                            :class="getActiveClass('cart')">Ready for Payment
                        </router-link>
                    </li>

                    <li>
                        <router-link :to="{ name: 'inquries' }" class="text-gray-900 dark:text-white"
                            :class="getActiveClass('inquries')">Customer Support</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav> -->


    <nav class="bg-gray-50 dark:bg-gray-700">
        <div class="max-w-screen-xl px-4 py-3 mx-auto">
            <div class="flex items-center justify-between">
                <div class="lg:hidden">
                    <button @click="menuOpen = !menuOpen" type="button"
                        class="text-gray-900 dark:text-white focus:outline-none" aria-label="Toggle Menu">
                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M4 6h16M4 12h16m-7 6h7"></path>
                        </svg>
                    </button>
                </div>

                <div class="hidden lg:flex">
                    <ul class="flex flex-row font-medium space-x-8 rtl:space-x-reverse text-sm">
                        <li>
                            <router-link :to="{ name: 'shipment' }" class="text-gray-900 dark:text-white"
                                :class="getActiveClass('shipment')">Create Shipment</router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'partial' }" class="text-gray-900 dark:text-white"
                                :class="getActiveClass('partial')">Partial Package</router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'draft' }" class="text-gray-900 dark:text-white"
                                :class="getActiveClass('draft')">Draft Package</router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'history' }" class="text-gray-900 dark:text-white"
                                :class="getActiveClass('history')">Order History</router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'cart' }" class="text-gray-900 dark:text-white"
                                :class="getActiveClass('cart')">Ready for Payment
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'inquries' }" class="text-gray-900 dark:text-white"
                                :class="getActiveClass('inquries')">Customer Support</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div v-if="menuOpen" class="lg:hidden">
            <ul class="flex flex-col font-medium space-y-2 text-sm px-4 py-3">
                <li>
                    <router-link :to="{ name: 'shipment' }" class="text-gray-900 dark:text-white"
                        :class="getActiveClass('shipment')">Create Shipment</router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'partial' }" class="text-gray-900 dark:text-white"
                        :class="getActiveClass('partial')">Partial Package</router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'draft' }" class="text-gray-900 dark:text-white"
                        :class="getActiveClass('draft')">Draft Package</router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'history' }" class="text-gray-900 dark:text-white"
                        :class="getActiveClass('history')">Order History</router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'cart' }" class="text-gray-900 dark:text-white"
                        :class="getActiveClass('cart')">Ready for Payment
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'inquries' }" class="text-gray-900 dark:text-white"
                        :class="getActiveClass('inquries')">Customer Support</router-link>
                </li>
            </ul>
        </div>
    </nav>
</template>

<style>
.active {
    font-weight: bold;
    /* You can customize this as per your design */
    border-bottom: 3px solid #3490dc;
    /* Example active state style */
}
</style>

<script>
export default {
    name: "ShipmentHeader",
    computed: {
        currentRoute() {
            return this.$route.name;
        }
    },
    data() {
        return {
            sidebar: false,
            token: null,
            name: null,
            menuOpen: false,
        };
    },
    methods: {
        closeSidebar() {
            this.sidebar = false;
        },
        getActiveClass(routeName) {
            return this.currentRoute === routeName ? 'active' : '';
        },
        toggleMenu() {
            this.menuOpen = !this.menuOpen;
        },
    },
    mounted() {
        this.token = localStorage.getItem('token');
        this.name = localStorage.getItem('name');
    }
};
</script>