<template>
    <LoaderComponent v-if="loading"></LoaderComponent>


    <div class="container mx-auto py-5 sm:px-10 px-3 space-y-5">

        <ShipmentHeader></ShipmentHeader>
        <DangerAlert :message="errors.message"></DangerAlert>

        <div class="relative overflow-x-auto rounded-lg mt-3">
            <table class="min-w-[1200px] w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase  dark:bg-gray-700 dark:text-gray-400 bg-[#F8EACA]">
                    <tr>
                        <th scope="col" class="px-6 py-3">Sr.no.</th>
                        <th scope="col" class="px-6 py-3">Package</th>
                        <th scope="col" class="px-6 py-3">Sender</th>
                        <th scope="col" class="px-6 py-3">Receiver</th>
                        <th scope="col" class="px-6 py-3">Service</th>
                        <th scope="col" class="px-6 py-3">Payment</th>
                        <th scope="col" class="px-6 py-3">Total Amount</th>
                        <th scope="col" class="px-6 py-3">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="packages.data && packages.data.length > 0">
                        <tr v-for="(pkg, index) in packages.data" :key="pkg.id"
                            class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 font-medium text-gray-900">
                            <td class="px-6 py-4">
                                {{ ++index }}
                            </td>
                            <td class="px-6 py-4">
                                Package ID: {{ pkg.id }} <br>
                                <template v-for="box in pkg.boxes" :key="box.id">
                                    <p>
                                        Dimension: {{ box.length }} x {{ box.width }} x {{ box.height }} {{ box.dim_unit
                                        }} <br>
                                        Weight: {{ box.weight }} {{ box.weight_unit }}
                                    </p>
                                </template>
                            </td>
                            <td scope="row" class="px-6 py-4 whitespace-nowrap dark:text-white">
                                <template v-if="pkg?.ship_from">
                                    <p class="pt-2"><b>Name:</b> {{ pkg?.ship_from?.fullname }}</p>
                                    <p><b>Address:</b> {{ pkg?.ship_from?.address }}</p>
                                    <p v-if="pkg?.ship_from?.address_2"><b>Address 2:</b> {{ pkg?.ship_from?.address_2
                                        }}</p>
                                    <p v-if="pkg?.ship_from?.address_3"><b>Address 3:</b> {{ pkg?.ship_from?.address_3
                                        }}</p>
                                    <p><b>Zip code:</b> {{ pkg?.ship_from?.zip_code }}</p>
                                    <p><b>City:</b> {{ pkg?.ship_from?.city }}</p>
                                    <p><b>Country:</b> {{ pkg?.ship_from?.country?.name }}</p> <br>
                                </template>
                            </td>
                            <td scope="row" class="px-6 py-4 whitespace-nowrap dark:text-white">
                                <template v-if="pkg?.ship_to">
                                    <p class="pt-2"><b>Name:</b> {{ pkg?.ship_to?.fullname }}</p>
                                    <p><b>Address:</b> {{ pkg?.ship_to?.address }}</p>
                                    <p v-if="pkg?.ship_to?.address_2"><b>Address 2:</b> {{ pkg?.ship_to?.address_2 }}
                                    </p>
                                    <p v-if="pkg?.ship_to?.address_3"><b>Address 3:</b> {{ pkg?.ship_to?.address_3 }}
                                    </p>
                                    <p><b>Zip code:</b> {{ pkg?.ship_to?.zip_code }}</p>
                                    <p><b>City:</b> {{ pkg?.ship_to?.city }}</p>
                                    <p><b>Country:</b> {{ pkg?.ship_to?.country?.name }}</p> <br>
                                </template>
                            </td>
                            <td scope="row" class="px-6 py-4 whitespace-nowrap dark:text-white">
                                {{ pkg.service_label }} <br>
                                {{ pkg.service_code }} <br>
                                {{ pkg.package_type_code }} <br>
                                {{ pkg.tracking_number_out }}
                            </td>
                            <td class="px-6 py-4">
                                {{ pkg.payment_status }}
                            </td>
                            <td class="px-6 py-4">
                                ${{ pkg.grand_total }}
                            </td>
                            <td class="px-6 py-4">
                                <button :id="`dropdownMenuIconButton-${pkg.id}`" @click="toggleDropdown(pkg.id)"
                                    class="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                                    type="button">
                                    <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor" viewBox="0 0 4 15">
                                        <path
                                            d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                                    </svg>
                                </button>
                                <div v-if="openDropdownId === pkg.id" :id="`dropdownDots-${pkg.id}`"
                                    class="z-10  bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                                    <div class="py-2">
                                        <a href="#"
                                            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                                            @click="editPackage(pkg.id)">Edit</a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>

                    <tr v-else>
                        <td colspan="7">
                            <div class="flex flex-col items-center justify-center text-center p-6 mt-10"
                                style="margin-bottom: 150px;">
                                <h1 class="text-lg sm:text-xl font-semibold text-gray-700 mb-2">There are no packages in
                                    the cart.</h1>

                                <img class="h-[65px] sm:h-[72px] w-[100px] sm:w-[120px] sm:object-contain mt-2"
                                    src="../../assets/logo.svg" alt="No Orders">
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <button type="button" @click="bulkPayment" v-if="packages.data && packages.data.length > 0"
            class="bg-[#f6ba3b] hover:bg-[#f7a900]text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
            Bulk Payment
        </button>

        <!-- <Pagination @fetchRecords="fetchRecords" :pagination="packages" v-if="packages.data && packages.data.length > 0"></Pagination> -->
    </div>
</template>

<script>
// import Pagination from "../Global/Pagination.vue";
import LoaderComponent from "../Partials/LoaderComponent.vue";
import ShipmentHeader from './ShipmentHeader.vue';
import DangerAlert from '../Global/Alerts/DangerAlert.vue';

export default {
    name: "CartComponent",
    data() {
        return {
            packages: [],
            loading: false,
            project_url: process.env.VUE_APP_PROJECT_URL,
            form: {
                package_id: "",
            },
            errors: {},
            openDropdownId: null,
        };
    },
    components: { DangerAlert, LoaderComponent, ShipmentHeader },

    methods: {
        fetchRecords() {
            this.loading = true;
            const url = "package/index";

            var filters = {
                package_status_id: [3]
            }

            this.$axios
                .post(url, filters, {
                    headers: this.$headers,
                })
                .then((response) => {
                    this.packages = response.data.data.packages;
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },

        bulkPayment() {
            this.loading = true;
            const url = "package/bulk-payment";

            this.$axios
                .post(url, null, {
                    headers: this.$headers,
                })
                .then((response) => {
                    console.log(response)
                    window.location.href = this.project_url + '/square-bulk-payment';
                    this.loading = false;
                })
                .catch((error) => {
                    this.errors = error.response.data;
                    this.loading = false;
                });
        },
        toggleDropdown(id) {
            this.openDropdownId = this.openDropdownId === id ? null : id;
        },
        editPackage(package_id) {
            this.errors = {};
            this.loading = true;
            this.form.package_id = package_id;
            this.$axios
                .post("package/edit-package", this.form, {
                    headers: this.$headers
                })
                .then((response) => {
                    this.$router.push(`/shipment/${response.data.data.package_id}`)
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.fetchRecords();
    }
};
</script>